<template>
  <div class="registry">
    <vue-particles color="#fff" :particleOpacity="0.7" :particlesNumber="60" shapeType="circle" :particleSize="4"
      linesColor="#fff" :linesWidth="1" :lineLinked="true" :lineOpacity="0.4" :linesDistance="150" :moveSpeed="2"
      :hoverEffect="true" hoverMode="grab" :clickEffect="true" clickMode="push" class="lizi"> 22 </vue-particles>
    <div class="content">
      <h3 style="text-align:center;color:#707070;font-size:20px;font-weight:bold;margin-bottom:20px;">注册</h3>

      <registryComponent @success="success" @login="login" />

      <!-- <el-button @click="goToLoginPage" id="login-btn" type="text" size="large">去登录</el-button> -->
    </div>
  </div>
</template>
<script>
// import uploadFile from '@/components/uploadFile.vue'
import registryComponent from '@/pages/registry/registryComponent.vue';
export default {
  name: 'registry',
  components: {
    registryComponent
    // uploadFile,
  },
  data() {
    return {
    }
  },
  mounted() {
    window.localStorage.setItem('token', '')
  },
  methods: {
    success() {

      this.$router.replace({
        path: '/home'
      })
    },
    login() {

      this.$router.replace({
        path: '/login'
      })
    }
  }
}
</script>
<style lang="less" scoped>
.registry {
  height: 100vh;
  overflow: hidden;
  background-image: url('./../../assets/loginBac.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  .content {
    width: 650px;
    border-radius: 10px;
    background: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 30px 20px;
    box-sizing: border-box;

    #login-btn {
      margin-left: auto;
      display: flex;
      flex-direction: column-reverse;
    }
  }
}
</style>